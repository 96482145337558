import React from 'react';
import Case from '../../../sections/Case';
import {useEnCaseData} from '../../../shared/Hooks/Case/useEnCaseData';
import {useEnHeaderData} from '../../../shared/Hooks/Headers/useEnHeaderData';

const CaseVox = () => {
  const data = {
    seo: {
      title: 'VOX – a modular display system',
    },
    cases: useEnCaseData().vox,
    header: useEnHeaderData().vox,
  };

  return (
    <Case data={data}/>
  );
};

export default CaseVox;
